import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import '~/styles/Menu.scss'

const Menu = () => {
	useEffect(() => {
		const script = document.createElement("script");
		script.src = "https://translate.yandex.net/website-widget/v1/widget.js?widgetId=ytWidget&pageLang=en&widgetTheme=light&autoMode=false";
		script.async = true;

		document.body.appendChild(script);
	});

	return (
		<aside className="global-menu">
			<div id="ytWidget"></div>
			<nav className="global-menu-nav">
				<li><AnchorLink className="global-menu-nav-link" href="#products">Products</AnchorLink></li>
				<li><Link className="global-menu-nav-link" href="#machines">Production lines</Link></li>
			</nav>
			<div className="global-menu-contacts">
				<div className="global-menu-contacts-email">
					<a href="mailto:info@bostonbiolab.com">info@pahhk.com</a>
				</div>
				<div className="global-menu-contacts-phone">
					<div>Office <strong>(852) 5103 4897</strong></div>
					<div>Direct <strong>(852) 3106 3698</strong></div>
				</div>
				<div className="global-menu-contacts-address">
					Pacific Atlantic Holdings, HK Limited.<br />
					Room 1405, 14/F., Loon Kee Building<br />
					267-275 Des Voeux Road Central,<br />
					Hong Kong
				</div>
			</div>
		</aside>
	)
}

export default Menu
