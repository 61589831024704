import React, { useContext, useState, useEffect } from 'react'
import reduce from 'lodash/reduce'

import { Link, navigate } from "gatsby"

import '~/styles/Header.scss'
import LogoText from '~/images/logo-text.png'
import LogoBg from '~/images/logo-bg.png'
import User from '~/svgs/user.svg'
import Product from '~/svgs/product.svg'
import Cart from '~/svgs/cart.svg'
import CartFull from '~/svgs/cart-full.svg'
import ArrowBack from '~/svgs/arrow-back.svg'

const Header = () => {
	const [menuOpened, setMenuOpened] = useState(false)
	const menuToggle = () => setMenuOpened(!menuOpened);

	if (typeof window !== 'undefined') {

		if (menuOpened === true) document.body.classList.add('menu-opened');
		else document.body.classList.remove('menu-opened');

	}

	const [items, setItems] = useState(1)

	useEffect(() => {
		if (typeof window !== 'undefined') {
			var menuLinks = document.querySelectorAll('.global-menu-nav a');
			if (menuLinks) menuLinks.forEach(function (element) {
				element.addEventListener('click', () => { setMenuOpened(false) });
			});

			var main = document.getElementsByTagName("main")[0];
			if (main) main.addEventListener('click', function () { if (menuOpened === true) setMenuOpened(false) });
		}
		return () => {
			if (typeof window !== 'undefined') {
				if (main) main.removeEventListener('click', function () { if (menuOpened === true) setMenuOpened(false) });
				if (menuLinks) menuLinks.forEach(function (element) {
					element.addEventListener('click', () => { setMenuOpened(false) });
				});
			}
		};
	});

	return (
		<header className="global-header" id="header">
			{typeof window !== 'undefined' && window.location.pathname === '/' ?
				<button className="menu-button" onClick={menuToggle}>
					<span className="menu-button-icon">
						<span className="b cb b1"></span>
						<span className="b cb b2"></span>
						<span className="b cb b3"></span>
						<span className="b cb b4"></span>
						<span className="b cb b5"></span>
					</span>
					<span className="menu-button-text">Menu</span>
				</button>
				:
				<ArrowBack className="back-button" onClick={() => { document.referrer.indexOf(window.location.host) !== -1 ? window.history.back() : navigate("/") }} />
			}
			<ul onClick={menuToggle} className="language-selector">
				<li>EN</li>
				<li>RU</li>
			</ul>
			<Link className="global-header-logo" to="/">
				<img className="global-header-logo-bg" alt="Pacific Atlantic Holdings, HK Limited" src={LogoBg} />
				<img className="global-header-logo-text" alt="Pacific Atlantic Holdings, HK Limited" src={LogoText} />
			</Link>
			<Link className="buy-button" to="/#products">
				<Product className="buy-button-icon" />
				<span className="buy-button-title">Products</span>
			</Link>
			{/*<Link className="account-informer">
				<User className="account-informer-icon not-logged" />
				<span className="account-informer-name">Alexey Lebed</span>
			</Link>*/}
			<a className="cart-informer snipcart-checkout" href='#'>
				{items ?
					<>
						<CartFull className="cart-informer-icon" />
						<span className="cart-informer-value snipcart-items-count">{items}</span>
					</>
					:
					<Cart className="cart-informer-icon" />
				}
			</a>
		</header >
	)
}

export default Header
